import React, { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, EditSelect, ICommand, Input, RelayCommand } from "Application";
import { RoundedInputSuffix } from "../RoundedInputSuffix";
import { SpanEightBox, SpanTwoBox } from "../SpanBoxes";
import { AddressGenericViewModel } from "./AddressGenericViewModel";
import { ManualSwitchTextBox, GridWrapper, ManualAddr, PAddress, PLink, RoundedInputStyle } from "./AddressGeneric.style";
import { getDefaultZoomLevel, getSharmansPin } from "Components/Maps/Helpers";
import {  latLng, LatLngExpression } from "leaflet";
import { MapContainer,  Marker, TileLayer } from "react-leaflet";
import { MapPlaceholder } from "Views/Directory/Properties/ViewProperty/ViewPropertyView.style";
import { P } from "Components/Primitives/TextElements/TextElements";
import { LatLng } from "leaflet";
import { LatLongEditor } from "./LatLongEditor";
import { UNITEDKINGDON } from "Globals/GlobalSettings";
import { isNullOrUndefined } from "util";
import { MapHack } from "Components/Maps/MapHack";
import DownArrow from "Assets/Icons/EditSelectArrow.svg";

interface IAddressGeneric {
    addressViewModel: AddressGenericViewModel;
    showAddressFields: boolean;
    setForceCounty?: boolean;
}

export interface ILocation {
    lat: number;
    lng: number;
}

export const AddressGeneric: React.FC<IAddressGeneric> = observer(({ addressViewModel, showAddressFields, setForceCounty }) => {
    const [refineLocationState, setRefineLocationState] = useState<boolean>(false);
    const [location, setLocation] = useState<ILocation>({ lat: 0, lng: 0 });
    const markerRef = useRef(null);

    useEffect(() => {
        if (showAddressFields === true) {
            addressViewModel.setAddressFieldsPanelOpen();
        }

        let promise = addressViewModel.getCountries();

        return () => {
            // Clean up after yourself
            //    viewModel.clear();
            setRefineLocationState(false);
            setLocation({ lat: 0, lng: 0 });
        };
    }, []);

    useEffect(() => {
        addressViewModel.setForceCounty(setForceCounty);
    }, [setForceCounty]);

    useEffect(() => {
        if (
            (addressViewModel.model.latitude !== 0 || addressViewModel.model.longitude !== 0) &&
            addressViewModel.model.latitude !== null &&
            addressViewModel.model.longitude !== null &&
            isNaN(addressViewModel.model.latitude) === false &&
            isNaN(addressViewModel.model.longitude) === false
        ) {
            setLocation({ lat: addressViewModel.model.latitude, lng: addressViewModel.model.longitude });



        }
    }, [addressViewModel.model.latitude, addressViewModel.model.longitude]);

    const showLocationModal: ICommand = new RelayCommand(
        () => {
            if (addressViewModel.canShowMapPin === false) {

                if (addressViewModel.model.countryId === UNITEDKINGDON
                    && addressViewModel.model.manualAddressEntry === false
                    && addressViewModel.model.latitude === 0 && addressViewModel.model.longitude === 0
                    && addressViewModel.model.postcode.length >= 6
                )
                {
                    let promise = addressViewModel.populateLatLongFromPostcode();

                    promise.then(() => {
                        if (addressViewModel.canShowMapPin === false) {
                            // error been shown so cancel
                        } else {
                            setRefineLocationState(!refineLocationState);
                        }
                    });
                } else
                {
                    setRefineLocationState(!refineLocationState);
                }

            } else {
                setRefineLocationState(!refineLocationState);
            }
        },
        () => {
            return addressViewModel.canShowLocationModal;
        },
    );

    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker: any = markerRef.current;
                if (marker != null) {
                    const position: LatLng = marker.getLatLng();
                    setLocation(position);
                    addressViewModel.model.latitude = position.lat;
                    addressViewModel.model.longitude = position.lng;
                }
            },
        }),
        [],
    );

    const position: [number, number] = [location.lat, location.lng];
    const center: LatLngExpression = latLng(location.lat,location.lng);

    return (
        <>
            <Box grid dc="1fr 4fr 1fr" pb="15px">
                    <>
                        <Input
                            displayName="Address search"
                            placeholder="Postcode"
                            command={addressViewModel.setSearchPostcodeCommand}
                            validationMessage={() => addressViewModel.getError("postcode")}
                            style={RoundedInputStyle}
                            value={() => addressViewModel.getValue("postcode")}
                            suffix={<RoundedInputSuffix text="Find address" onClick={addressViewModel.findAddressesCommand} disabled={!addressViewModel.setSearchPostcodeCommand.canExecute()}/>}
                            autoFill={false}

                        />
                        <EditSelect
                            displayName="Select an address"
                            command={addressViewModel.setAddress}
                            options={addressViewModel.addresses}
                            value={() => addressViewModel.model.selectedAddress.key}
                            isDisabled={addressViewModel.isProcessing === true || addressViewModel.model.manualAddressEntry === true}
                        />
                    </>
                <ManualSwitchTextBox>
                    <ManualAddr>
                        <span className="link" onClick={addressViewModel.setManualAddressEntry}>
                            {addressViewModel.getManualAddressEntry === true ? "Search address" : "Manual address entry"}
                        </span>
                    </ManualAddr>
                    {addressViewModel.showAddressFieldsPanel === true ? (
                        <PAddress>
                            <span className="link" onClick={addressViewModel.setAddressFieldsPanelClosed}>
                                <img src={DownArrow} style={{ transform: "rotate(0deg)  scale(3,3)" }} />
                            </span>
                        </PAddress>
                    ) : (
                        <PAddress>
                            <span className="link" onClick={addressViewModel.setAddressFieldsPanelOpen}>
                                <img src={DownArrow} style={{ transform: "rotate(180deg) scale(3,3)" }} />
                            </span>
                        </PAddress>
                    )}
                </ManualSwitchTextBox>
            </Box>
            <GridWrapper showIf={addressViewModel.showAddressFieldsPanel === true}>
                <SpanTwoBox>
                    <Input
                        displayName="Address line 1 *"
                        placeholder="Enter address line 1"
                        validationMessage={() => addressViewModel.getError("addressLineOne")}
                        command={addressViewModel.setAddressOne}
                        value={() => addressViewModel.getValue("addressLineOne")}
                        onBlur={addressViewModel.onBlurCommand}
                        autoFill={false}
                    />
                </SpanTwoBox>
                <SpanTwoBox>
                    <Input
                        displayName="Address line 2"
                        placeholder="Enter address line 2"
                        validationMessage={() => addressViewModel.getError("addressLineTwo")}
                        command={addressViewModel.setAddressTwo}
                        value={() => addressViewModel.getValue("addressLineTwo")}
                        onBlur={addressViewModel.onBlurCommand}
                        autoFill={false}
                    />
                </SpanTwoBox>
                <SpanTwoBox>
                    <Input
                        displayName="Town/City *"
                        placeholder="Enter town/city"
                        command={addressViewModel.setTownCity}
                        validationMessage={() => addressViewModel.getError("townCity")}
                        value={() => addressViewModel.getValue("townCity")}
                        onBlur={addressViewModel.onBlurCommand}
                        autoFill={false}
                    />
                </SpanTwoBox>
                <SpanTwoBox>
                    <Input
                        displayName={"County" + (setForceCounty !== undefined && setForceCounty === true ? " *" : "")}
                        placeholder="Enter county"
                        validationMessage={() => addressViewModel.getError("county")}
                        command={addressViewModel.setCounty}
                        value={() => addressViewModel.getValue("county")}
                        autoFill={false}
                    />
                </SpanTwoBox>
                <SpanTwoBox>
                    <EditSelect
                        command={addressViewModel.updateCountryId}
                        displayName="Country *"
                        placeholder="Enter country"
                        options={addressViewModel.countries}
                        validationMessage={() => addressViewModel.getError("countryId")}
                        value={() => (addressViewModel.model.countryId === null ? "-1" : addressViewModel.model.countryId.toString())}
                        errorIconRight={false}
                    />
                </SpanTwoBox>
                <SpanTwoBox>
                    <Input
                        displayName="Postcode *"
                        placeholder="Enter postcode"
                        command={addressViewModel.setManualPostcodeCommand}
                        validationMessage={() => addressViewModel.getError("postcode")}
                        value={() => addressViewModel.getValue("postcode")}
                        onBlur={addressViewModel.onBlurCommand}
                        autoFill={false}
                    />
                </SpanTwoBox>

                <SpanTwoBox mt={26}>
                    <Button
                        mr={2}
                        command={showLocationModal}
                        displayName={"Refine Location"}
                        style={{ padding: "5px 20px" }}
                        paletteColor={addressViewModel.isProcessing === true || addressViewModel.canShowLocationModal === false ? "ButtonInactive" : "ButtonBlue"}
                    />
                </SpanTwoBox>
            </GridWrapper>

            {refineLocationState === true && addressViewModel.isProcessing === false && (
                <>
                    <PLink mt="20px" mb="20px">
                        <span className="link" onClick={addressViewModel.setLatLongPanelState}>
                            {addressViewModel.showLatLongPanel === true ? "Hide Latitude & Longitude" : "Show Latitude & Longitude" }
                        </span>
                    </PLink>

                    {addressViewModel.showLatLongPanel === true && (
                        <GridWrapper mt="20px" mb="20px">
                            <LatLongEditor addressViewModel={addressViewModel} />
                        </GridWrapper>
                    )}

                    <SpanEightBox>
                        {addressViewModel.canShowMap? (
                            <MapContainer
                                style={{ width: "100%", height: "433px", zIndex: 0 }}
                                center={position}
                                zoom={getDefaultZoomLevel()}
                                scrollWheelZoom={false}
                            >
                                <MapHack boundsToFit={undefined} center={center}/>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker eventHandlers={eventHandlers} ref={markerRef} draggable={true} icon={getSharmansPin()} position={[location.lat, location.lng]}></Marker>
                            </MapContainer>
                        ) : (
                            <MapPlaceholder height="433px">
                                <P>No location has been set for this property.</P>
                            </MapPlaceholder>
                        )}
                    </SpanEightBox>
                </>
            )}
        </>
    );
});
