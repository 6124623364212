import styled from "@emotion/styled";
import { P } from "Components/Primitives/TextElements/TextElements";
import { Box, EditSelect, Input } from "Application";

export const GridWrapper = styled(Box)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 30px;
    row-gap: 12px;

    .leaflet-default-icon-path,
    .leaflet-marker-pane {
        background-image: url(/assets/pins/marker-icon.png);
    }
`;
export const RoundedInputStyle = {
    borderRadius: "0.125rem 10px 10px 0.125rem",
};


export const CenteredTextBox = styled(Box)`
    display: flex;
    align-items: end;
    padding-bottom: 14px;
`;

export const ManualSwitchTextBox = styled(Box)`
    display: flex;
    align-items: end;
    padding-bottom: 14px;

    /* background-color: orange; */
`;

export const PLink = styled(P)`
    font-weight: 300;
    /* background-color: green; */

    .link {
        color: #337ab7;
        text-decoration: underline;
        cursor: pointer;
    }
`;


export const PAddress = styled(P)`
    font-weight: 300;
    margin-right: 5px;
    max-width: 50px;
    position: relative;
    margin-top: -10px;
    height: 20px;
    /* background-color: green; */

    .link {
        color: #337ab7;
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const ManualAddr = styled(P)`
    display: flex;
    flex: 1 1 0;
    font-weight: 300;
    vertical-align: center;
    align-items: center;
    text-align: center;

   /*  background-color: yellow; */

    .link {
        color: #337ab7;
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const ButtonBox = styled(Box)`
    position: absolute;
    top: 0;
    right: 0;
`;

export const AddSectionsBox = styled(Box)`
    position: relative;
`;
