export const StartOfTime: string = "2018-03-01";

export const UNITEDKINGDON = 79;

export const MINPOSTCODELEN = 5;

// Sharman's Table defaults
export const DefaultPageSize: number = 100;

// Master / Project Quote NetValue defaults
export const DefaultMinNetValue: number = 0;
export const DefaultMaxNetValue: number = 5000000;

// Condition Report
export const A4Width: number = 794;
export const A4Height: number = 1123;
export const DefaultImageSize: number = 350; // Was 250 in origianl document
export const DefaultImageGap: number = 22; // Original was 85.  This is deprecated

export const AllowedFileTypes = [
    ".doc",
    ".docx",
    ".pdf",
    ".xls",
    ".xlsx",
    ".csv",
    ".jpg",
    ".png",
    ".msg",
    ".jpeg",
    ".accdb",
    ".bmp",
    ".eml",
    ".mp4",
    ".odt",
    ".txt",
    ".xlsm",
    ".zip",
    ".oft",
    ".json",
    ".exe",
];
